import { Link } from 'react-router-dom';
import AppIcon from '../app_icon/app_icon';
import BlurryText from '../blurry_text/blurry_text';
import BoldText from '../bold_text/bold_text';
import StoreButton from '../store_button/store_button';
import './first_section.css';

  

export default function FirstSection() {
    return (
        <div className='d-flex first-section'>
            <div className='d-flex left-side'>
                <div className='d-flex flex-vertical d-flex-space-between'>
                    <AppIcon className='home-logo no-mobile' />
                    <div className='d-flex-self-center'>
                        <p className='home-rider no-margin text-bold-500 font-size-65'>
                            <span className='app-primary-color'>Delivery by</span><br />
                            <span className='app-accent-color'>Trusted Travelers,</span>
                        </p>
                        <p className='header-description mobile-font-size no-margin'>
                            <BlurryText text='Make important deliveries to ' />
                            <BoldText className='text-bold' text='clients, family and friends ' />
                            <BlurryText text='using verified travellers on CrowdExpress' />
                        </p>
                        <StoreButton className='first-section-store' />
                    </div>
                </div>
            </div>
            <div className='right-side'>
                <div className='d-flex no-mobile right-side-nav j-content-end text-bold'>
                    <Link className='home-nav-link home-login-link text-white text-bold-500' to='/login'>
                        LOGIN
                    </Link>
                    <Link className='home-nav-link home-register-link app-accent-color text-bold-500' to='/register'>
                        SIGN UP
                    </Link>
                </div>
            </div>
        </div>
    );
}